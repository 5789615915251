import {
    JSON_EXTENSION,
    LANGUAGE_COOKIE_NAME,
    LANGUAGE_ENGLISH
} from '../constants/Misc.constants';
import Cookies from 'js-cookie';
import EnumService from './Enum.service';

export default class LanguageService {

    static currentLanguage = LANGUAGE_ENGLISH;
    static language = {};
    static error = false;
    static handleLanguageChangeCallback = undefined;

    static async initializeViaRequire(handleLanguageChangeCallback) {
        var getLanguage = Cookies.get(LANGUAGE_COOKIE_NAME);
        if (!getLanguage) {
            Cookies.set(LANGUAGE_COOKIE_NAME, LanguageService.currentLanguage);
        } else {
            LanguageService.currentLanguage = getLanguage;
        }
        await LanguageService.requireLanguageFile();
        this.handleLanguageChangeCallback = handleLanguageChangeCallback;
    }

    static switchLanguage(lang) {
        if (!LanguageService.isLanguage(lang)) {
            LanguageService.currentLanguage = lang;
            LanguageService.requireLanguageFile();
            Cookies.set(LANGUAGE_COOKIE_NAME, lang);
        }

        //required for One Trust language switch
        if (document.documentElement.lang !== LanguageService.currentLanguage) {
            //set index.html lang
            document.documentElement.lang = LanguageService.currentLanguage;

            if (window.OneTrust) {
                window.OneTrust.changeLanguage();
            }
        }

        if (this.handleLanguageChangeCallback) 
            this.handleLanguageChangeCallback(lang);
        else
            window.location.reload();
    }

    static isLanguage(lang) {
        return LanguageService.currentLanguage === lang;
    }

    static isEnglish() {
        return LanguageService.isLanguage(LANGUAGE_ENGLISH);
    }

    static requireLanguageFile() {
        let language = require('../language/' + LanguageService.currentLanguage + JSON_EXTENSION);
        LanguageService.language = language;
    }

    static translate(key) {
        if (LanguageService.language[key]) {
            return LanguageService.language[key];
        } else {
            return key;
        }
    }

    static chooseLang(valueEn, valueFr) {
        if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
            return (valueEn) ? valueEn : 'N/A';
        } else {
            return (valueFr) ? valueFr : 'N/A';
        }
    }

    static translateCouponPaymentFrequency(couponPaymentFrequencyEn) {
        if(LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
            return couponPaymentFrequencyEn;
        }
        else {
            const found = EnumService.getEnums('coupon_frequency').find(e => e.valueEn === couponPaymentFrequencyEn);
            if(found) {
                return found.valueFr;
            }
            else {
                return couponPaymentFrequencyEn;
            }
        }
    }

}

const tr = LanguageService.translate;
const chooseLang = LanguageService.chooseLang;
const isEnglish = LanguageService.isEnglish;
export { 
    tr,
    chooseLang,
    isEnglish
};
