import React, {useEffect, useState} from "react";
import HttpClient from "../../utils/HttpClient";
import {
  REST_GA_FLAG_PATH,
  REST_GA_TRACKING_ID_PATH,
} from "../../constants/Rest.constants";
import ReactGA from "react-ga4";
import {useCookies} from "react-cookie";
import {isEmpty} from "../../utils/valueUtils";

const OneTrustComponent = () => {
  const [isGAEnabled, setGAEnabled] = useState(false);
  const [gaTrackingId, setGATrackingId] = useState("");
  const [cookies, , removeCookie] = useCookies();

  const ONE_TRUST_COOKIE_NAME = "OptanonConsent";
  const ONE_TRUST_TRACKING_DISABLED = "C0004:0";
  const ONE_TRUST_TRACKING_ENABLED = "C0004:1";

  useEffect(() => {
    let oneTrustId = getOneTrustId();

    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute(
      "src",
      `https://cdn.cookielaw.org/consent/${oneTrustId}/otSDKStub.js`
    );
    script.setAttribute("data-document-language", "true");
    script.setAttribute("charSet", "UTF-8");
    script.setAttribute("data-domain-script", `${oneTrustId}`);

    //add OneTrust script
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    async function getGAEnabledFlag() {
      const gaFlagData = await HttpClient.get(REST_GA_FLAG_PATH);
      setGAEnabled(
        !(
          gaFlagData &&
          gaFlagData.googleAnalyticsFlag &&
          gaFlagData.googleAnalyticsFlag === "false"
        )
      );
    }

    getGAEnabledFlag();
  }, []);

  useEffect(() => {
    async function getGATrackId() {
      const trackingIdData = await HttpClient.get(REST_GA_TRACKING_ID_PATH);
      setGATrackingId(trackingIdData.gaTrackingId);
    }

    getGATrackId();
  }, []);

  React.useEffect(() => {
    if (!isGAEnabled || isEmpty(gaTrackingId)){
      return;
    }

    const cookieValue = cookies[ONE_TRUST_COOKIE_NAME];
    if (!cookieValue ||isEmpty(cookieValue)){
      return;
    }

    const googleTrackedCookieName = "_ga_" + removePrefix(gaTrackingId);

    //if cookie has COO4 flag set to 0 and Google Analytics tracking cookie exists
    if (
      cookieValue.indexOf(ONE_TRUST_TRACKING_DISABLED) > 0 &&
      cookies[googleTrackedCookieName]
    ) {
      const domain = extractDomain(window.location.hostname);
      const attributes = {path: "/", domain: domain};

      removeCookie(googleTrackedCookieName, attributes);
      removeCookie("_ga", attributes);
      removeCookie("_gat", attributes);
      removeCookie("_gid", attributes);

      window.location.reload();
    }

    //if cookie has COO4 flag set to 1
    if (
      cookieValue.indexOf(ONE_TRUST_TRACKING_ENABLED) > 0
    ) {
      ReactGA.initialize(gaTrackingId, {
        gaOptions: {
          //debug_mode: true,
        },
      });
    }
  }, [cookies, removeCookie, isGAEnabled, gaTrackingId]);

  const getOneTrustId = () => {
    let oneTrustId = "23b66028-5b83-4480-8e7f-0e4f77c54f34";
    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("dev.") ||
      window.location.href.includes("pat.")
    ) {
      oneTrustId += "-test";
    }

    return oneTrustId;
  };

  //returns .td.com or .tdsecurities.com or .tdbank.com or localhost
  const extractDomain = (hostname) => {
    let domain = "";

    if (hostname.includes("localhost")) {
      domain = "localhost";
    } else {
      const parts = hostname.split(".");

      if (parts.length > 1) {
        domain = "." + parts[parts.length - 2] + "." + parts[parts.length - 1];
      }
    }

    return domain;
  };

  const removePrefix = (trackerId) => {
    return trackerId.substring(trackerId.indexOf("-") + 1, trackerId.length);
  };
};

export default OneTrustComponent;
